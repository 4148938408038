<template>
  <default>
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'
  import gql from 'graphql-tag'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
        title: 'Tuatara Structures',
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
      computedTitle() {
        if (this.$store.state.title) {
          return `${this.title} : ${this.$store.state.title}`
        }
        else if(this.$route.meta.title) {
          return `${this.title} : ${this.$route.meta.title}`
        } else   {
          return this.title
        }
      },
      computedDescription(){
        if (this.$store.state.description == '' || this.$store.state.description == null)
          return this.$store.state.defaultDescription
        else
          return this.$store.state.description
      }
    },
    apollo: {
      // footer settings
      footer: { query: gql` query {
          footer: globalSets {
            ...on seo_GlobalSet{
              seoDefaultTitlePrefix seoDefaultDescription
            }
            ...on footer_GlobalSet{
              footerEmail footerPhone footerAddress1 footerAddress2 linkedin facebook youtube
              privacyPolicy{
                ...on general_Asset{
                  title url
                }
              }
            } 
          }
        }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.footer !== null){
              data.footer.forEach(function (value, index){
                switch(value.__typename){
                  case 'footer_GlobalSet':
                    // console.log('Footer GQL Data:')
                    // console.log(value)
                    this.$store.commit('setFooterData', value)
                    this.$store.commit('setFooterLoaded', true)
                  break;
                  case 'seo_GlobalSet':
                    this.$store.commit('setDefaultDescription', value.seoDefaultDescription)
                  break;

                }
              }.bind(this));
              // console.log('settings now loaded')
              // console.log(this.$store.state.defaultDescription)
              this.$store.commit('setSettingsLoaded', true);
            } 
            else{
             this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
        skip() {
          return this.skipQuery
        },
      }
    },
  	methods: {
  		setup() {
        if (this.$store.state.footerData === null){
          this.$apollo.queries.footer.skip = false
          this.$apollo.queries.footer.refetch()
        }
  			this.cssVars();
  			console.log('%cBrand and Copy : http://www.thecopyroom.co.nz', 'color: #000000; font-weight: bold;');
        console.log('%cWeb Design : https://www.bathgatedesign.co.nz', 'color: #000000; font-weight: bold;');
        console.log('%cWeb Development: https://kichin.co', 'color: #000000; font-weight: bold;');
        console.log('–');
  			this.breakpointCheck();
  		},
  		resize(e) {
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else {
          breakpoint = 'xl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        // Handle VH units : get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
      }
    },
    metaInfo() {
      return {
        title: this.computedTitle,
        meta: [
          {
          name: 'description',
          content: this.computedDescription
          },
          {
          property: 'og:title',
          content: this.computedTitle
          },
          {
          property: 'og:description',
          content: this.computedDescription
          },
        ],
        link: [
            {rel: 'canonical', href: this.$store.state.fullURL + this.$route.fullPath}
          ]
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>